<div
  CloseOnClickOutside
  (clickOutside)="handleClickOutside($event)"
  class="overlay"
  [class.background-corp]="state?.projetoAtivo?.educacao_corporativa"
  [class.background-sup]="!state?.projetoAtivo?.educacao_corporativa"
>
  <div class="box">
    <div class="close-pattern" (click)="closeSaibaMais()">
      <!-- <i class="fas fa-times pointer"></i> -->
      <img src="assets/icons/fas-fa-times-pointer-gray.svg" />
    </div>
    <div class="saiba-mais-tag">
      {{ "SAIBA_MAIS.PROJECT" | translate }}
      <span>{{ "SAIBA_MAIS.PROJECT1" | translate }}</span>
    </div>
    <div class="content">
      <div class="content__nome" [innerHtml]="truncatedNameHtml"></div>
      <div class="content__circle">
        <div class="circle-blue"></div>
        <div class="circle-blue"></div>
        <div class="circle-blue"></div>
      </div>
      <div
        class="content__text-disciplina"
        [innerHtml]="capitalizedText | sanitizeHtml"
      ></div>
      <div class="content__tag-container">
        <div class="tag-graduacao">
          {{ tipoGraduacao }}
        </div>
        <div class="tag-duracao">{{ state?.projetoAtivo?.duracao }} h</div>
        <!-- <div class="tag-competencia">
          <img src="../../assets/icons/award-blue.svg" />
          <span class="nome-competencia">
            blabla
          </span>
        </div> -->
        <div class="tag-competencia">
          <img src="../../../assets/icons/award-blue.svg" />
          <span
            class="nome"
            *ngIf="state?.projetoAtivo?.projetoTurmaCompetencia[0]"
            >{{
              state?.projetoAtivo?.projetoTurmaCompetencia[0]?.nome
                | slice : 0 : 25
            }}
          </span>
        </div>
      </div>
      <div
        class="content__video-container"
        *ngIf="state?.projetoAtivo?.ancora"
        [ngSwitch]="videoSource(state?.projetoAtivo?.ancora)"
      >
        <div
          class="video-border youtube"
          *ngSwitchCase="'youtube'"
          [innerHtml]="
            generateYoutubeFrame(state?.projetoAtivo?.ancora) | sanitizeHtml
          "
        ></div>
        <div class="video-border vimeo" *ngSwitchCase="'vimeo'">
          <div
            name="vimeo"
            class="vimeo"
            [id]="state?.projetoAtivo?.ancora"
            data-vimeo-responsive="true"
          ></div>
        </div>
        <div
          class="video-border sambavideo"
          *ngSwitchCase="'sambavideo'"
          [innerHtml]="
            generateSambaVideoFrame(state?.projetoAtivo?.ancora) | sanitizeHtml
          "
        ></div>
      </div>
      <!-- <div *ngIf="state?.projetoAtivo?.questao_motriz" class="title text">
        {{ "SAIBA_MAIS.MOTRIZ" | translate }}
      </div> -->
      <div
        class="content__text"
        [innerHtml]="state?.projetoAtivo?.descricao | sanitizeHtml"
      ></div>
      <hr class="content__break-line" />
      <div class="profile">
        <div
          class="profile__profileImage"
          [style.background-image]="
            globalService.generateImageURL(
              state.projetoAtivo?.projetoTurmaTutor[0]?.foto
            )
          "
          [ngStyle]="{
            border: state?.projetoAtivo?.projetoTurmaTutor[0]?.foto
              ? '6px solid #fff'
              : 'none'
          }"
          alt="Foto do professor-tutor"
        >
          <img
            *ngIf="!state?.projetoAtivo?.projetoTurmaTutor[0]?.foto"
            src="../../../assets/imgs/profile-img.svg"
          />
        </div>
        <div class="profile__text">
          <div class="name-tutor" [innerHtml]="tutorNameHtml"></div>
          <div class="minibio-tutor">
            {{ state?.projetoAtivo?.projetoTurmaTutor[0]?.minibio }}
          </div>
        </div>
      </div>
      <div class="whatToLearn">
        <div class="whatToLearn__title">
          {{ "SAIBA_MAIS.LEARN" | translate }}
        </div>
        <div class="whatToLearn__list">
          <ul class="list-ul">
            <li *ngFor="let obj of todosObjetivosAprendizagem">
              {{ obj.nome }}
            </li>
          </ul>
          <hr class="content__break-line" />
          <div class="whatToLearn__title">
            {{ "PROJECT.DELIVERY" | translate }}
          </div>
        </div>
        <div class="whatToLearn__description-entrega">
          <ul class="list-ul">
            <li *ngFor="let entrega of arrEntregas">
              {{ entrega.item_entrega_turma }}
            </li>
          </ul>
        </div>
        <button class="btn" (click)="closeSaibaMais()">
          {{ "SAIBA_MAIS.LETS_START_THE_COURSE" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
