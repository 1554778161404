<div class="avaliacao-container">
  <div class="main">
    <div *ngIf="isLoading && useInternalLoading" class="text-center">
      <div class="spinner-border spinner-border-md" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="!isLoading" class="main-card">
      <!-- <div *ngIf="this.entregaTurma && this.entregaTurma.questoes_tentativa" class="nota mb-4">
        {{ "GABARITO.ACERTOS" | translate }}
        <span> {{ resultado?.tentativa.acertos }}</span> de
        {{ this.entregaTurma.questoes_tentativa }}
      </div> -->
      <div class="d-flex justify-content-center">
        <cls-circle
          [size]="'big'"
          [textColor]="'#0D0D0D'"
          [strokeFill]="'#F5F5F5'"
          [percentage]="getGabaritoPercentage()"
          [value]="resultado?.tentativa?.acertos"
        >
        </cls-circle>
      </div>
      <div class="title mt-4 capitalize">
        {{ "GABARITO.TITLE" | translate }}
      </div>
      <div
        *ngFor="let question of resultado?.tentativa?.questions; let i = index"
      >
        <div class="label-gabarito mt-3 mb-1">
          {{ "GABARITO.ASK" | translate }} {{ i + 1 }}
        </div>
        <div [innerHTML]="question.pergunta" class="question mt-3"></div>
        <div
          *ngIf="
            question?.id_quiz_entrega_turma_tipo === 1 ||
            question?.id_quiz_entrega_turma_tipo === 2
          "
        >
          <div class="answer" *ngFor="let option of question.respostas">
            <div
              class="check"
              [class.checked]="option.alternativa.correta"
              [class.right]="option.alternativa.correta && option.marcada"
              [class.wrong]="!option.alternativa.correta && option.marcada"
            >
              <i
                *ngIf="!(!option.alternativa.correta && option.marcada)"
                class="fas fa-check"
              ></i>
              <i
                *ngIf="!option.alternativa.correta && option.marcada"
                class="fas fa-times"
              ></i>
            </div>
            <div class="divider"></div>
            {{ option.alternativa.texto }}
          </div>
        </div>
        <div *ngIf="question?.id_quiz_entrega_turma_tipo === 3">
          <div class="flex-row" *ngFor="let option of question.respostas">
            <div
              class="letter-ranking"
              id="letter-{{ i }}"
              [class.right]="
                option.ordenacao === option.alternativaRanking.ordenacao
              "
              [class.wrong]="
                option.ordenacao !== option.alternativaRanking.ordenacao
              "
            >
              <span>{{ option.letra_alternativa }}</span>
            </div>
            <div class="answer-ranking">
              {{ option.alternativaRanking.texto }}
            </div>
          </div>
          <div class="correcao-container">
            <span class="correcao-label">{{
              "GABARITO.CORRECT_ANSWER" | translate
            }}</span>
            <span class="correcao">{{ question.correcao }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
