import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  private static removeElement(el: HTMLElement, elQuery: string) {
    const deletedEl = el.querySelectorAll(elQuery);

    deletedEl.forEach(value => {
      if (value) {
        value.remove();
      }
    });
  }

  private static removeEmptyParagraph(el: HTMLElement) {
    const paragraphEl = el.querySelectorAll('p');

    paragraphEl.forEach(value => {
      if (value.textContent === ' ') {
        value.remove();
      }
    });
  }

  private static appendClass(el: HTMLElement, elQuery: string, className: string) {
    const imgsEl = el.querySelectorAll(elQuery);

    imgsEl.forEach(value => {
      if (value.parentElement) {
        value.parentElement.className += className;
      }
    });
  }

  private static appendAttribute(el: HTMLElement, elQuery: string, attrName: string, attrValue: string) {
    const linkEl = el.querySelectorAll(elQuery);
    linkEl.forEach(value => {
      value.setAttribute(attrName, attrValue);
    });
  }

  transform(value: string, ...args) {
    const elValue = document.createElement('div');
    elValue.innerHTML = value;

    if (value) {
      SanitizeHtmlPipe.removeEmptyParagraph(elValue);
      SanitizeHtmlPipe.removeElement(elValue, 'p br');
      SanitizeHtmlPipe.appendClass(elValue, 'p img', 'cls-img-full');
      SanitizeHtmlPipe.appendClass(elValue, 'p iframe', 'cls-iframe-full');
      SanitizeHtmlPipe.appendAttribute(elValue, 'a', 'target', '_blank');
    }

    return this._sanitizer.bypassSecurityTrustHtml(elValue.innerHTML);
  }
}
