import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { showSaibaMais } from 'src/app/state/state.actions';
import { SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'cls-saiba-mais',
	templateUrl: './saiba-mais.component.html',
	styleUrls: ['./saiba-mais.component.scss'],
})
export class SaibaMaisComponent implements OnInit, OnDestroy {
  @Input() project;
  selectedFase: any;
  state: any;
  todosObjetivosAprendizagem = [];
  stateSubscription$: any;
  tutorSelected: any;
  public tutorNameHtml: SafeHtml;
  tipoGraduacao: string;
  truncatedNameHtml: SafeHtml;
  arrEntregas: Array<any> = [];
  capitalizedText: string;

  constructor(
    public globalService: GlobalService,
    private store: Store<AppState>
  ) {}

  ngOnDestroy(): void {
  	this.stateSubscription$.unsubscribe();
  }

  ngOnInit() {
  	this.getState();
  	this.tutorSelected = this.state.projetoAtivo.projetoTurmaTutor;
  	if (this.state.fases) {
  		this.selectedFase = this.state.fases[0];
  		this.globalService.vimeo();
  		this.getTodosObjetivosAprendizagem();
  	}
  	this.updateTutorNameHtml();
  	this.findTipoGraduação();
  	this.truncatedNameHtml = this.truncatedName(
  		this.state?.projetoAtivo?.nome,
  		120
  	);
  	this.capitalizedText = this.capitalizeWords(
  		this.state?.projetoAtivo?.nome_disciplina
  	);
  	this.getAllEntregas();
  }

  ngOnChanges() {
  	setTimeout(() => {
  		this.globalService.vimeo();
  	}, 1000);
  }

  getState() {
  	this.stateSubscription$ = this.store
  		.select((state: any) => {
  			this.state = state.newAppState;
  		})
  		.subscribe();
  }

  getTodosObjetivosAprendizagem() {
  	if (!this.state || !this.state.fases) return;
  	this.todosObjetivosAprendizagem = [];

  	this.state.fases.forEach((fase) => {
  		fase.objetivosAprendizagem.forEach((objetivo) => {
  			this.todosObjetivosAprendizagem.push(objetivo);
  		});
  	});

  	this.todosObjetivosAprendizagem = this.todosObjetivosAprendizagem.sort(
  		(a, b) => b.id_objetivo_aprendizagem - a.id_objetivo_aprendizagem
  	);
  	this.todosObjetivosAprendizagem = [
  		...new Map(
  			this.todosObjetivosAprendizagem.map((item) => [
  				item['id_objetivo_aprendizagem'],
  				item,
  			])
  		).values(),
  	].filter((objetivo) => objetivo.nome !== null && objetivo !== '');
  }

  generateSambaVideoFrame(ancora: string) {
  	// usar embed link
  	const iframe = `<iframe allowfullscreen webkitallowfullscreen mozallowfullscreen width="100%" height="360" src="${ancora}" scrolling="no" frameborder="0" allow="geolocation; microphone; camera; encrypted-media; midi">`;
  	return iframe;
  }

  generateYoutubeFrame(ancora: string) {
  	// usar embed link
  	const youtubeVideoId = this.getYoutubeVideoIdFromUrl(ancora);
  	const iframe = `<iframe id='ytplayer' type='text/html' width='100%' height='372' src="https://www.youtube.com/embed/${youtubeVideoId}" frameborder='0'></iframe>`;
  	return iframe;
  }

  getYoutubeVideoIdFromUrl(url: string): string {
  	const YOUTUBE_VIDEO_ID_LENGTH = 11;
  	const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  	const match = url.match(regExp);

  	if (match && match[2].length >= YOUTUBE_VIDEO_ID_LENGTH) return match[2];
  	else {
  		let videoUrl = url;
  		if (videoUrl.includes('https://')) videoUrl = videoUrl.slice(8);
  		const index = videoUrl.indexOf('/');
  		return index !== -1 ? videoUrl.slice(videoUrl.indexOf('/') + 1) : 'error';
  	}
  }

  videoSource(ancora) {
  	let ret = null;
  	if (!ancora) return ret;
  	switch (true) {
  	case ancora.indexOf('vimeo.com') !== -1:
  		ret = 'vimeo';
  		break;
  	case ancora.indexOf('youtu') !== -1:
  		ret = 'youtube';
  		break;
  	case ancora.indexOf('liquidplatform.com') !== -1:
  		ret = 'sambavideo';
  		break;
  	default:
  		break;
  	}
  	return ret;
  }

  activeFase(fase) {
  	this.selectedFase = fase;
  }

  closeSaibaMais() {
  	this.store.dispatch(showSaibaMais({ payload: false }));
  	document.querySelector('body').removeAttribute('class');
  }

  handleClickOutside(event) {
  	setTimeout(() => {
  		this.closeSaibaMais();
  	}, 300);
  }

  updateTutorNameHtml() {
  	const tutorName =
      this.state?.projetoAtivo?.projetoTurmaTutor[0]?.nome_social || '';
  	this.tutorNameHtml = `Professor(a) ${tutorName}`;
  }

  findTipoGraduação() {
  	const value = this.state?.projetoAtivo?.id_nivel_ensino;

  	switch (value) {
  	case 1:
  		this.tipoGraduacao = 'Graduação';
  		break;
  	case 2:
  		this.tipoGraduacao = 'Pós-Graduação';
  		break;
  	case 3:
  		this.tipoGraduacao = 'Extensão';
  		break;
  	case 4:
  		this.tipoGraduacao = 'Curso Livre';
  		break;
  	case 5:
  		this.tipoGraduacao = 'Corporativo';
  		break;
  	default:
  		break;
  	}
  }

  //criar uma função que limita um texto a 120 caracteres

  truncatedName(nameText: string, limit: number): string {
  	if (!nameText) return '';
  	return nameText.length > limit
  		? nameText.substring(0, limit) + '...'
  		: nameText;
  }

  getAllEntregas() {
  	if (!this.state || !this.state.fases) return;

  	this.arrEntregas = [];
  	this.state?.fases.forEach((fase) => {
  		fase.entregaTurma.forEach((entrega) => {
  			this.arrEntregas.push(entrega);
  		});
  	});

  	this.arrEntregas = [
  		...new Map(this.arrEntregas.map((item) => [item, item])).values(),
  	].filter(
  		(entrega) =>
  			entrega.item_entrega_turma !== null && entrega.item_entrega_turma !== ''
  	);

  	this.arrEntregas = this.arrEntregas.sort((a, b) => {
  		return a.ordenacao - b.ordenacao;
  	});
  }

  capitalizeWords(text: string): string {
  	if (!text) return text;

  	let result = text
  		.toLowerCase()
  		.replace(/(^\s*\w|[.!?]\s*\w)/g, (char) => char.toUpperCase());

  	result = result.replace(
  		/\b[A-Z][a-z]*\b/g,
  		(char) => char.charAt(0).toUpperCase() + char.slice(1).toLowerCase()
  	);

  	return result;
  }
}
