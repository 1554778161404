<div class="perfilComponent">
  <div class="background-container">
    <div
      class="background-image"
      [style.background-image]="backgroundImg"
    ></div>
  </div>
  <div class="img-perfil-container">
    <div
      class="profile-image"
      [style.background-image]="global.generateImageURL(state?.usuario?.foto)"
    >
      <div class="medalha-and-cam" *ngIf="quantidadeDeCompetencias">
        <img
          class="medalha"
          src="assets/imgs/medalha-pequena.svg"
          *ngIf="!editModeUserData"
        />
        <div class="div-new" *ngIf="!editModeUserData">
          <span>{{ quantidadeDeCompetencias }}</span>
        </div>
        <img
          class="set-photo-perfil"
          (click)="callInput(); $event.stopPropagation()"
          src="assets/icons/icone-camera.svg"
          *ngIf="editModeUserData"
        />
        <input
          type="file"
          class="d-none upload-picture"
          (change)="prepareUpload($event)"
        />
      </div>
    </div>
  </div>
  <div class="name-container">
    <div class="title" *ngIf="!editModeUserData">
      {{ state?.usuario?.nome_social }}
    </div>
    <input
      class="form"
      type="text"
      *ngIf="editModeUserData"
      [(ngModel)]="user.nome_social"
      [placeholder]="'CURRICULO_EDIT.EDITION_NAME' | translate"
    />
  </div>
  <div class="polo-container">
    <div class="sub-title">
      {{ state?.dadosPolo?.nome || state?.dadosEmpresa?.nome }}
    </div>
  </div>
  <div class="tag-container">
    <div class="tag" *ngFor="let competencia of filtereds">
      <img src="assets/icons/award-blue.svg" />
      <span class="nome-competencia">
        {{ competencia.nome | slice : 0 : 25 }}</span
      >
    </div>
  </div>
  <div class="block-container" *ngIf="!editModeUserData">
    <div class="title-row">
      <span class="img-adjust"></span>
      <label class="title">{{ "CURRICULO.SOCIAL_MEDIA" | translate }}</label>
      <img
        src="assets/icons/lapis.svg"
        class="img-button"
        (click)="setUserData(true)"
        data-cy="CurriculoEditar"
      />
    </div>
    <div class="socials-row-container">
      <div
        *ngFor="let rede of socialMedia"
        class="social"
        data-cy="CurriculoRedesSociais"
        (click)="
          state?.usuario['rede_' + rede]
            ? goToSocialMedia(state?.usuario['rede_' + rede], rede)
            : $event.stopPropagation()
        "
      >
        <img
          class="social-media"
          src="{{
            state?.usuario['rede_' + rede]
              ? 'assets/icons/' + rede + '.svg'
              : 'assets/icons/' + rede + '-gray.svg'
          }}"
          alt="{{ rede }}"
        />
      </div>
    </div>
  </div>
  <div class="social-edit-container" *ngIf="editModeUserData">
    <label class="title-social">
      {{ "CURRICULO.SOCIAL_MEDIA" | translate }}
    </label>
    <div class="socials-form-container">
      <input
        type="text"
        class="form"
        [(ngModel)]="user.rede_linkedin"
        [placeholder]="'https://linkedin.com/in/nome'"
        [title]="'CURRICULO_EDIT.EDITION_LINKEDIN' | translate"
      />
      <input
        type="text"
        class="form"
        [(ngModel)]="user.rede_twitter"
        [placeholder]="'https://twitter.com/nome'"
        [title]="'CURRICULO_EDIT.EDITION_TWITTER' | translate"
      />
      <input
        type="text"
        class="form"
        [(ngModel)]="user.rede_facebook"
        [placeholder]="'https://facebook.com/nome'"
        [title]="'CURRICULO_EDIT.EDITION_FACEBOOK' | translate"
      />
      <input
        type="text"
        class="form"
        [(ngModel)]="user.rede_instagram"
        [placeholder]="'https://instagram.com/nome'"
        [title]="'CURRICULO_EDIT.EDITION_INSTAGRAM' | translate"
      />
    </div>
  </div>
  <div class="block-container curriculo-container">
    <div class="title-row">
      <span class="img-adjust"></span>
      <label class="title">{{ "PERFIL.MY_RESUME" | translate }}</label>
      <img
        src="assets/icons/lapis.svg"
        class="img-button"
        (click)="setUserData(true)"
        data-cy="CurriculoEditar"
      />
    </div>
    <div class="group-container">
      <label class="group-title">
        {{ "CURRICULO.PERSONAL_DATA" | translate }}
      </label>
      <div class="group-simpletext">
        <span *ngIf="!editModeUserData">
          {{ state?.usuario?.email_pessoal }}
        </span>
        <span *ngIf="!editModeUserData">
          Tel.: {{ state?.usuario?.celular || "Telefone para contato" }}
        </span>
        <input
          class="form"
          type="text"
          *ngIf="editModeUserData"
          [(ngModel)]="user.email_pessoal"
          [placeholder]="'CURRICULO_EDIT.PERSONAL_DATA_EMAIL' | translate"
        />
        <input
          class="form"
          type="text"
          *ngIf="editModeUserData"
          [(ngModel)]="user.celular"
          [placeholder]="'CURRICULO_EDIT.PERSONAL_DATA_TEL' | translate"
        />
      </div>
    </div>
    <div class="aboutMe group-container">
      <label class="group-title">
        {{ "CURRICULO.ABOUT" | translate }}
      </label>
      <div
        class="group-simpletext"
        [class.hide-text]="isTextHidden && showHide"
        [title]="state?.usuario.curriculoUsuario[0]?.sobremim"
        *ngIf="!editModeUserData"
      >
        {{
          state?.usuario.curriculoUsuario[0]?.sobremim ||
            "Descreva um pouco sobre você..."
        }}
      </div>
      <span
        class="open-section"
        (click)="toggleText()"
        *ngIf="showHide && !editModeUserData"
      >
        <img
          [ngClass]="{
            'img-chevron-down': isTextHidden,
            'img-chevron-up': !isTextHidden
          }"
          src="assets/icons/chevron-top-blue.svg"
        />
      </span>
      <textarea
        class="form"
        *ngIf="editModeUserData"
        [(ngModel)]="user.curriculoUsuario[0].sobremim"
      ></textarea>
    </div>
    <div class="formacao group-container">
      <label class="group-title">
        {{ "CURRICULO.FORMATION" | translate }}
      </label>
      <img
        src="assets/icons/plus-blue.svg"
        class="img-plus"
        (click)="setPerfilEdition(true, 'catFormacao')"
        data-cy="CurriculoEditar"
      />
    </div>
    <div class="formacao" *ngFor="let formacao of catFormacao; let i = index">
      <div class="formacao-box">
        <div class="formacao-percent">
          <cls-circle
            *ngIf="!formacao.editing"
            [size]="'medium'"
            [textColor]="'#8e8e8e'"
            [strokeFill]="'#F5F5F5'"
            [percentage]="formacao.valor_progresso.toString()"
          >
          </cls-circle>
        </div>
        <div class="formacao-text-group">
          <span class="formacao-title" *ngIf="!formacao.editing">
            {{ formacao.curso }}
          </span>
          <span class="formacao-nivel-ensino" *ngIf="!formacao.editing">
            {{ formacao.nivel_ensino }}
          </span>
          <span class="formacao-instituicao" *ngIf="!formacao.editing">
            {{ formacao.instituicao }}
          </span>
          <div
            class="edit-formacao-container"
            *ngIf="formacao.editing && editModecatFormacao"
          >
            <input
              type="text"
              class="form"
              [(ngModel)]="formacao.curso"
              [placeholder]="'CURRICULO_EDIT.FORMATION_COURSE' | translate"
              [title]="'CURRICULO_EDIT.FORMATION_COURSE' | translate"
            />
            <select
              class="form"
              [(ngModel)]="formacao.nivel_ensino"
              [title]="'CURRICULO_EDIT.FORMATION_LEVEL' | translate"
            >
              <option value="" disabled selected hidden>
                {{ "CURRICULO_EDIT.FORMATION_LEVEL" | translate }}
              </option>
              <option *ngFor="let nivel of arrNivelEnsino" [value]="nivel">
                {{ nivel }}
              </option>
            </select>
            <input
              type="text"
              class="form"
              [(ngModel)]="formacao.instituicao"
              [placeholder]="'CURRICULO_EDIT.FORMATION_INSTITUTION' | translate"
              [title]="'CURRICULO_EDIT.FORMATION_INSTITUTION' | translate"
            />
          </div>
        </div>
        <div class="edit-delete-img-formacao-container">
          <img
            *ngIf="!formacao.editing"
            src="assets/icons/lapis.svg"
            class="img-button"
            (click)="setPerfilEdition(true, 'catFormacao', i)"
            data-cy="CurriculoEditar"
          />
          <img
            (click)="togglecatFormacao(i)"
            class="img-button"
            *ngIf="editModecatFormacao"
            data-cy="CurriculoExperienciaDeletar"
            src="../../../assets/icons/trash-2.svg"
          />
        </div>
      </div>
    </div>
    <div
      class="buttons-container"
      *ngIf="editModecatFormacao || editModeUserData"
    >
      <button
        class="btn cancel"
        (click)="
          editModecatFormacao
            ? cancelEditData('catFormacao')
            : cancelEditData('curriculo')
        "
        data-cy="CurriculoBtnCancelar"
      >
        {{ "CURRICULO_EDIT.EDITION_CANCEL" | translate }}
      </button>
      <button
        class="btn save"
        (click)="
          editModecatFormacao ? saveData('formacao') : saveData('curriculo')
        "
        data-cy="CurriculoBtnSalvar"
      >
        {{ "CURRICULO_EDIT.EDITION_SAVE" | translate }}
      </button>
    </div>
  </div>
  <section class="competencia" *ngIf="showContent">
    <div class="group-container">
      <label class="group-title">
        {{ "CURRICULO.SKILLS" | translate }}
        <img
          src="assets/icons/plus-blue.svg"
          class="img-plus"
          (click)="setPerfilEdition(true, 'catCompetencia')"
          data-cy="CurriculoEditar"
        />
      </label>

      <span class="group-subtitle">
        {{ "CURRICULO.SKILLS_DESCRIPTIONS" | translate }}
      </span>
    </div>
    <div class="content-box">
      <div
        class="competencia-box"
        *ngFor="let competencia of catCompetencia | slice : 0 : limitComp"
      >
        <cls-circle
          *ngIf="!competencia.editing"
          [size]="'medium'"
          [value]="competencia?.valor?.toString()"
          [textColor]="'#8e8e8e'"
          [strokeFill]="'#F5F5F5'"
          [percentage]="competencia?.valor"
        >
        </cls-circle>
        <div class="competencia-text-group">
          <label class="competencia-aboutCompetencia">
            {{ competencia.descricao }}
          </label>
          <div
            class="certificado"
            *ngIf="
              sistema_configs.sistema_certificados &&
              checkIfAprovado(competencia) &&
              checkIfHasSignature(competencia)
            "
            (click)="
              competencia.certificado_url
                ? downloadCertified(competencia)
                : emitCertified(competencia)
            "
          >
            <img
              src="assets/icons/download-blue.svg"
              class="img-adjust"
              data-cy="CurriculoEditar"
            />
            <span>{{ "BUTTON.CERTIFICATION" | translate }}</span>
          </div>
        </div>
        <div
          class="star"
          data-cy="CurriculoDivFavoriteCompetencia"
          *ngIf="editModecatCompetencia"
        >
          <img
            class="pointer"
            (click)="toggleFavorita(competencia)"
            *ngIf="!competencia.favorita"
            src="../../../assets/icons/star.svg"
          />
          <img
            class="pointer"
            (click)="toggleFavorita(competencia)"
            *ngIf="competencia.favorita"
            src="../../../assets/icons/star-blue.svg"
          />
        </div>
      </div>
    </div>
    <span
      class="open-section"
      (click)="toggleShowAllCompetencias()"
      *ngIf="catCompetencia.length > 5 && !editModecatCompetencia"
    >
      <img
        [ngClass]="{
          'img-chevron-down': !showAllCompetencias,
          'img-chevron-up': showAllCompetencias
        }"
        src="assets/icons/chevron_up_blue.svg"
      />
    </span>
    <div class="buttons-container" *ngIf="editModecatCompetencia">
      <button
        class="btn cancel"
        (click)="cancelEditData('catCompetencia')"
        data-cy="CompetenciaBtnCancelar"
      >
        {{ "CURRICULO_EDIT.EDITION_CANCEL" | translate }}
      </button>
      <button
        class="btn save"
        (click)="saveData('competencia')"
        data-cy="CompetenciaBtnSalvar"
      >
        {{ "CURRICULO_EDIT.EDITION_SAVE" | translate }}
      </button>
    </div>
  </section>
  <section class="idioma" *ngIf="showContent">
    <div class="group-container">
      <label class="group-title">
        {{ "CURRICULO.LANGUAGE" | translate }}
        <img
          src="assets/icons/plus-blue.svg"
          class="img-plus"
          (click)="setPerfilEdition(true, 'catIdioma')"
          data-cy="CurriculoEditar"
        />
      </label>
    </div>
    <div class="content-box" *ngIf="showContent">
      <div class="idioma-box" *ngFor="let idioma of catIdioma; let i = index">
        <div class="left fluencias">
          <div class="fluencia marked"></div>
          <div
            class="fluencia"
            [class.marked]="
              idioma.fluencia == 'Fluente' ||
              idioma.fluencia == 'Avançado' ||
              idioma.fluencia == 'Intermediário'
            "
          ></div>
          <div
            class="fluencia"
            [class.marked]="
              idioma.fluencia == 'Fluente' || idioma.fluencia == 'Avançado'
            "
          ></div>
          <div
            class="fluencia"
            [class.marked]="idioma.fluencia == 'Fluente'"
          ></div>
        </div>
        <label class="idioma-nome" *ngIf="!idioma.editing">
          {{ idioma.idioma }}
        </label>
        <select
          class="form"
          *ngIf="idioma.editing && editModecatIdioma"
          [(ngModel)]="idioma.idioma"
        >
          <option value="" disabled selected hidden>
            {{ "CURRICULO_EDIT.LANGUAGE_SELECT" | translate }}
          </option>
          <option *ngFor="let option of idiomasArray" [value]="option">
            {{ option }}
          </option>
        </select>
        <select
          *ngIf="idioma.editing && editModecatIdioma"
          class="form"
          [(ngModel)]="idioma.fluencia"
          data-cy="CurriculoSelectFluencia"
        >
          <option value="" disabled selected hidden>
            {{ "CURRICULO_EDIT.LANGUAGE_LEVEL" | translate }}
          </option>
          <option value="Básico" data-cy="CurriculoOptionFluenciaBasico">
            {{ "CURRICULO_EDIT.LANGUAGE_SELECT1" | translate }}
          </option>
          <option
            value="Intermediário"
            data-cy="CurriculoOptionFluenciaIntermediario"
          >
            {{ "CURRICULO_EDIT.LANGUAGE_SELECT2" | translate }}
          </option>
          <option value="Avançado" data-cy="CurriculoOptionFluenciaAvancado">
            {{ "CURRICULO_EDIT.LANGUAGE_SELECT3" | translate }}
          </option>
          <option value="Fluente" data-cy="CurriculoOptionFluenciaFluente">
            {{ "CURRICULO_EDIT.LANGUAGE_SELECT4" | translate }}
          </option>
        </select>
        <img
          src="assets/icons/lapis.svg"
          class="img-button"
          (click)="setPerfilEdition(true, 'catIdioma', i)"
          *ngIf="!editModecatIdioma"
          data-cy="CurriculoEditar"
        />
        <img
          (click)="togglecatIdioma(i)"
          class="trash pointer mr-3"
          *ngIf="editModecatIdioma"
          data-cy="CurriculoExperienciaDeletar"
          src="../../../assets/icons/trash-2.svg"
        />
      </div>
    </div>
    <div class="buttons-container" *ngIf="editModecatIdioma">
      <button
        class="btn cancel"
        (click)="cancelEditData('catIdioma')"
        data-cy="CurriculoBtnCancelar"
      >
        {{ "CURRICULO_EDIT.EDITION_CANCEL" | translate }}
      </button>
      <button
        class="btn save"
        (click)="saveData('idioma')"
        data-cy="CurriculoBtnSalvar"
      >
        {{ "CURRICULO_EDIT.EDITION_SAVE" | translate }}
      </button>
    </div>
  </section>
  <section class="experiencia" *ngIf="showContent">
    <div class="group-container">
      <label class="group-title">
        {{ "CURRICULO.EXPERIENCE" | translate }}
        <img
          src="assets/icons/plus-blue.svg"
          class="img-plus"
          (click)="setPerfilEdition(true, 'catExperiencia')"
          data-cy="CurriculoEditar"
        />
      </label>
    </div>
    <div class="content-box" *ngIf="showContent">
      <div
        class="experiencia-box"
        *ngFor="let experiencia of catExperiencia; let i = index"
      >
        <img
          src="assets/icons/briefcase-new-blue.svg"
          class="experiencia-briefcase"
          data-cy="CurriculoEditar"
        />
        <div class="experiencia-text-group">
          <div class="description">
            <label class="experiencia-empresa" *ngIf="!experiencia.editing">
              {{ experiencia.corporacao }}
            </label>
            <label class="experiencia-cargo" *ngIf="!experiencia.editing">
              {{ experiencia.cargo }}
            </label>
          </div>
          <label class="experiencia-periodo" *ngIf="!experiencia.editing">
            {{ "CURRICULO.FROM" | translate }}
            {{
              experiencia.data_inicio.includes("-")
                ? (experiencia.data_inicio | date : "MM/yyyy")
                : experiencia.data_inicio
            }}
            {{ "CURRICULO.TO" | translate }}
            {{
              experiencia.data_final.includes("-")
                ? (experiencia.data_final | date : "MM/yyyy")
                : experiencia.data_final
            }}
          </label>
          <input
            type="text"
            class="form"
            *ngIf="experiencia.editing && editModecatExperiencia"
            [(ngModel)]="experiencia.corporacao"
            [placeholder]="'CURRICULO_EDIT.EXPERIENCE_CORP' | translate"
            [title]="'CURRICULO_EDIT.EXPERIENCE_CORP' | translate"
          />
          <input
            type="text"
            class="form"
            *ngIf="experiencia.editing && editModecatExperiencia"
            [(ngModel)]="experiencia.cargo"
            [placeholder]="'CURRICULO_EDIT.EXPERIENCE_OFFICE' | translate"
            [title]="'CURRICULO_EDIT.EXPERIENCE_OFFICE' | translate"
          />
          <div class="date-experiencia-container">
            <input
              *ngIf="experiencia.editing && editModecatExperiencia"
              type="month"
              name="data_inicio"
              placeholder="MM/YYYY"
              [(ngModel)]="experiencia.data_inicio"
              class="form half-input"
              data-cy="CurriculoInputDataInicio"
            />
            <input
              *ngIf="experiencia.editing && editModecatExperiencia"
              type="month"
              name="data_final"
              placeholder="MM/YYYY"
              [(ngModel)]="experiencia.data_final"
              class="form half-input"
              data-cy="CurriculoInputDataFinal"
            />
          </div>
        </div>
        <div class="edit-img-experiencia-container">
          <img
            *ngIf="!experiencia.editing"
            src="assets/icons/lapis.svg"
            class="edit-box-experiencia"
            (click)="setPerfilEdition(true, 'catExperiencia', i)"
            data-cy="CurriculoEditar"
          />
        </div>
        <img
          (click)="togglecatExperiencia(i)"
          class="trash pointer mr-3"
          *ngIf="editModecatExperiencia"
          data-cy="CurriculoExperienciaDeletar"
          src="../../../assets/icons/trash-2.svg"
        />
      </div>
    </div>
    <div class="buttons-container" *ngIf="editModecatExperiencia">
      <button
        class="btn cancel"
        *ngIf="editModecatExperiencia"
        (click)="cancelEditData('catExperiencia')"
        data-cy="CurriculoBtnCancelar"
      >
        {{ "CURRICULO_EDIT.EDITION_CANCEL" | translate }}
      </button>
      <button
        class="btn save"
        (click)="saveData('experiencia')"
        data-cy="CurriculoBtnSalvar"
      >
        {{ "CURRICULO_EDIT.EDITION_SAVE" | translate }}
      </button>
    </div>
  </section>
  <div
    class="box-hidden-text"
    (click)="toggleContent()"
    *ngIf="!showContent"
    [ngStyle]="{ 'margin-top': showContent ? '0px' : '20px' }"
  >
    <span class="show-content" data-cy="CurriculoMostrarMais">
      {{ "CURRICULO.EXIBIR_MAIS" | translate }}
    </span>
    <img class="img-chevron-down" src="assets/icons/chevron_up_white.svg" />
  </div>
  <div class="box-hidden-text" (click)="toggleContent()" *ngIf="showContent">
    <span class="hide-content" data-cy="CurriculoMostrarMais">
      {{ "CURRICULO.EXIBIR_MENOS" | translate }}
    </span>
    <img class="img-chevron-up" src="assets/icons/chevron_up_white.svg" />
  </div>
</div>
